#box{
    width: 40vw;
    min-height: 30vh;
    background: #FFF;
    margin: 30vh auto;
}

#box-content{
    width: 60%;
    min-height: 100px;
    float:right;    
}

#logo{
    width: 40%;
    float: left;
}

#img-logo{
    width: 70%;
    margin: 7px 0 0 7px;
}

h1{
    text-align: center;
    font-family: 'Arial';
    font-size: 30px;
    color: #e64a19;
}

.botao-login{
    background-color: #ff5722 !important;
    color: #FFF;
    border: none;
}