#container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: hsl(200, 15%, 54%);
    box-shadow: 1px 1px 3px #37474f;    
}

#boxForm{
    width: 98%;
    background-color: #FFF;
    min-height: 20vh;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4vh 0 4vh 0;
}

#formControl{
    width: 95%;
    margin: 1vh auto;
    text-align: center;
}

#imgLogo{
    width: 50%;
    margin: 1vh auto 1vh auto;
}

#botaoLogin{
    display: block;
    width: 100%;
    background-color: #F8901F;
    box-shadow: 1px 1px 6px #b0bec5;
    border-radius: 4px;
    border: 1px solid #FFF;
}